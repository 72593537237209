<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">TERMS OF USE</div>
      </div>
      <div class="news_detail">
        <div class="text">
          This page (together with any other documents referred to on it) tells
          you the terms of use ("Terms") on which you may make use of our
          website http://www.ECIawards.org (our "Site"). Please read the Terms
          carefully before using our Site.
          <br />Who we are and how to contact us<br />
          <br />
          <div class="t2">By using our Site you accept these Terms:</div>
          1.You have to be at least 18 to access the Site. By using any part of
          our Site, you confirm that you accept these Terms and that you agree
          to comply with them. If you do not agree to these Terms, you will not
          use our Site. <br />
          <br />
          <div class="t2">There are other terms that may apply to you</div>
          2.The following additional terms may also apply to your use of our
          Site: <br />a)Our Privacy Policy, which sets out information about the
          provision of personal information by you and how we use it.
          <br />b)Our Cookie Policy, which sets out information about the
          cookies on our Site. <br />3.Where you access products and/or services
          via our Site, your access (and use) will be governed by the terms and
          conditions applied to those products and/or services in conjunction
          with these Terms. In the event of any conflict between these Terms and
          the relevant product or service terms, the latter will prevail and
          apply. <br /><br />
          <div class="t2">We may make changes to these Terms</div>
          4.We may revise these Terms at any time by posting an update on our
          Site. Your continued use of our Site after any such changes
          constitutes your acceptance of the new Terms. Every time you wish to
          use our Site, please check these Terms to ensure you understand the
          Terms that apply at that time, as they are binding on you. These Terms
          were most recently updated in July 2022.
          <br />
          <br />
          <div class="t2">We may make changes to our Site</div>
          5.We may update and change our Site from time to time to reflect
          changes to our services, our users' needs and our business priorities.
          <br /><br />
          <div class="t2">We may suspend or withdraw our Site</div>
          6.We do not guarantee that our Site, or any content on it, will always
          be available or be uninterrupted. We may suspend or withdraw or
          restrict the availability of all or any part of our Site for business
          and operational reasons. We will try to give you reasonable notice of
          any suspension or withdrawal. <br />7.You are also responsible for
          ensuring that all persons who access our Site through your internet
          connection are aware of these Terms and other applicable terms and
          conditions, and that they comply with them. <br />8.Please don’t
          breach the Terms, or we may be required to suspend or even cancel your
          access to ECI membership. <br />
          <br />
          <div class="t2">You will register your details correctly</div>
          9.To access certain parts of our Site, we may require you to register
          and provide certain information about yourself. When you register you
          agree to: <br />a. provide true, accurate, current and complete
          information about yourself as prompted by the relevant registration
          form; and <br />b. contact us using the “Contact Us” section of our
          Site in the event that you wish to update your information.
          <br /><br />
          <div class="t2">How you may use material on our Site</div>
          10.We are the owner or the licensee of all intellectual property
          rights in our Site, and in the material published on it. Those works
          are protected by copyright and other intellectual property laws and
          treaties around the world. All such rights are reserved. <br />11.You
          agree that in using our Site you will: <br />a)only use the material
          on our Site in accordance with the following permitted uses:
          <br />i.you may view them on a computer screen, print off only one
          copy of any page(s) from our Site; <br />ii.you may download extracts
          of any page(s) from our Site where permission to download and store
          the materials is specifically granted in the relevant materials, and
          that downloading was permitted in accordance with fair practice and
          only to the extent reasonably required in the circumstances;
          <br />iii.where permission to download and store material is
          specifically granted, you may download and store the content on the
          hard disk of your computer or portable media but not make any further
          transfer or copy of it; and <br />iv.you may only make such other use
          of the material as may be specifically authorized on our Site or
          within the relevant materials; <br />b)not distribute, reproduce,
          modify, store, transfer or in any other way use any of the paper or
          digital copies of any materials you have printed off or downloaded
          (including as part of any database, library, news, information,
          archive, website or similar service) unless specifically authorized by
          us or as set out above; <br />c)not disseminate advertisements on our
          Site or use our materials for any other commercial purposes (which
          would include using them to promote or encourage the sale of your
          goods/services) without our permition; <br />d)not disseminate any
          unsolicited or unauthorized advertising, promotional materials, "junk
          mail," "spam," "chain letters," "pyramid schemes," or any other form
          of solicitation; <br />e)not use any illustrations, photographs, video
          or audio sequences or any graphics separately from any accompanying
          text; <br />f)always acknowledges our status (and that of any
          identified contributors) as the authors of content on our Site;
          <br />g)not remove the copyright or trade mark notice(s) from our
          materials; and <br />h)not scrape, extract, download, upload, sell or
          offer for sale any of the material on our Site and you agree not to
          use, or cause to be used, any computerized or other manual or
          automated program or mechanism, tool, or process, including any
          scraper or spider robot, to access, extract, download, scrape, data
          mine, display, transmit, or publish, any of the material on our Site;
          <br />12.If you print off, copy, download or use any part of our Site
          in breach of these Terms, your right to use our Site will cease
          immediately and you will, at our option, return or destroy any copies
          of the materials you have made. <br />
          <br />
          <div class="t2">Do not rely on information on our Site</div>
          13.The content on our Site and any related material provided to you by
          us is provided for general information only. It does not amount to any
          form of advice or recommendation on which you should rely. You will
          obtain professional or specialist advice before taking, or refraining
          from, any action, specific investment or other business or personal
          decisions on the basis of the content on our Site.
          <br />14.Although we make reasonable efforts to update the information
          on our Site, we make no representations, warranties or guarantees,
          whether express or implied, that the content on our Site is accurate,
          complete or up to date. We cannot be held liable for its accuracy and
          timeliness, and we are under no obligation to update it. We may
          however update and make changes to the content on our Site, at any
          time without notice. <br />
          <br />
          <div class="t2">We are not responsible for websites we link to</div>
          15.Where our Site contains links to third party websites and resources
          provided by third parties, these links are provided for your
          information only. Such links should not be interpreted as approval by
          us of those linked websites or information you may obtain from them.
          <br />16.We have not reviewed these third party websites and have no
          control over the contents or availability of those websites or
          resources. If you decide to access any of the third party websites
          linked to our Site, you do so entirely at your own risk. <br /><br />
          <div class="t2">User-generated content is not approved by us</div>
          17.Our Site may include information and materials uploaded by other
          users of our Site. This information and these materials have not been
          verified or approved by us. The views expressed by other users on our
          Site do not represent our views or values. We accept no responsibility
          for any statements, material or other submissions placed on our Site
          by users. <br />18.If you wish to complain about information and
          materials uploaded by other users please contact us using the “Contact
          Us” section of our Site. <br /><br />
          <div class="t2">
            Our responsibility for loss or damage suffered by you
          </div>
          19.Nothing in these Terms will operate to exclude or limit our
          liability to you for death or personal bodily injury caused by us or
          our employees or subcontractors’ negligence, or for any fraudulent
          misrepresentation by any of the foregoing or for any other liability
          which cannot be excluded or restricted by law. <br />20.Subject to the
          foregoing: <br />a)we will not be liable to you arising out of or in
          connection with use of our Site for any of the following types of
          losses, damages, or expenses of any kind arising out of or in
          connection with such use; <br />i.consequential; <br />ii.indirect;
          <br />iii.special; <br />iv.lost profits; <br />v.lost revenue;
          <br />vi.lost sales; <br />vii.anticipated savings; and
          <br />viii.losses, damages, or expenses arising from loss of data;
          <br />b)our total will be decided according to circumstance <br />c)we
          will have no liability to you for any failure or delay in performing
          an obligation under these Terms because of any event beyond our or our
          subcontractors’ reasonable control. <br />21.You acknowledge and agree
          that in using our Site you have not relied on, and will have no remedy
          in respect of, any statement, representation, warranty, understanding,
          promise or assurance (whether negligently or innocently made) of any
          person other than as expressly set out in these Terms. <br />
          <br />
          <div class="t2">Uploading content to our Site</div>
          22.Whenever you make use of a feature that allows you to upload
          content to our Site, or to make contact with other users of our Site,
          you will comply with these Terms. You warrant that any such
          contribution does comply with these terms, and you will be liable to
          us for any loss or damage that we suffer as a result of your breach of
          that warranty. <br />23.You will ensure that you do not upload, post
          or disseminate: <br />a. any material that may interrupt, damage,
          destroy or limit the functionality of any computer software or
          hardware or telecommunications equipment, including, without
          limitation, computer viruses, logic bombs, Trojan horses, worms,
          harmful components, corrupted data or other malicious software or
          harmful data; <br />b. any material which is or may infringe the
          rights (including intellectual property rights) of any third party or
          be unlawful, threatening, defamatory, obscene, indecent, offensive,
          pornographic, abusive, liable to incite racial hatred, discriminatory,
          menacing, scandalous, inflammatory, blasphemous, in breach of
          confidence, in breach of privacy, which may cause annoyance or
          inconvenience or may restrict or inhibit the use of our Site by any
          person or which constitutes or encourages conduct that may be
          considered a criminal offence or give rise to civil liability in any
          country in the world; <br />c. any material which does or may bring us
          or any of our brands or subsidiaries into dispute or in any way damage
          their reputation; and <br />d. any link(s) that take users to material
          that contravenes any of the above restrictions. <br />24.Any content
          you upload to our Site will be considered non-confidential and
          non-proprietary. You retain all of your ownership rights in your
          content, but by uploading any content to our Site you grant us and
          other users of our Site a limited license to use, store and copy that
          content and to distribute and make it available to third parties.
          <br />25.We also have the right to disclose your identity to any third
          party who is claiming that any content posted or uploaded by you to
          our Site constitutes a violation of their intellectual property rights
          or of their right to privacy. <br />26.We have the right to remove any
          posting you make on our Site if, in our opinion, your post does not
          comply with these Terms. <br />27.You are solely responsible for
          securing and backing up your content. <br />
          <br />
          <div class="t2">
            We are not responsible for viruses and you will not introduce them
          </div>
          28.We do not guarantee that our Site and any related material provided
          by us to you will be secure or free from bugs, viruses or other
          malicious code. <br />29.You are responsible for configuring your
          information technology, computer programs and platform to access our
          Site. You should use your own virus protection software. <br />30.You
          will not misuse our Site by knowingly introducing viruses, trojans,
          worms, logic bombs or other material that is malicious or
          technologically harmful. You will not attempt to gain unauthorized
          access to our Site, the server on which our Site is stored or any
          server, computer or database connected to our Site. You will not
          attack our Site via a denial-of-service attack or a distributed
          denial-of service attack. By breaching this provision, you would
          commit a criminal offence under the Computer Misuse Act 1990. We will
          report any such breach to the relevant law enforcement authorities and
          we will co-operate with those authorities by disclosing your identity
          to them. In the event of any such breach, your right to use our Site
          will cease immediately. <br />
          <br />
          <div class="t2">Rules about linking to our Site</div>
          31.You may link to the home page of our Site, provided you do so in a
          way that is fair and legal and does not damage our reputation or take
          advantage of it. <br />32.You will not establish a link in such a way
          as to suggest any form of association, approval or endorsement on our
          part where none exists. <br />33.You will not establish a link to our
          Site in any website that is not owned by you. <br />34.Our site will
          not be framed on any other site, nor may you create a link to any part
          of our Site other than the home page. <br />35.We reserve the right to
          withdraw linking permission without notice. <br />36.If you wish to
          link to or make any use of content on our Site other than that set out
          above, please contact us using the “Contact Us” section of our Site.
          <br />
          <br />
          <div class="t2">Which country's laws apply to any disputes?</div>
          37.These Terms, their subject matter and their formation, are governed
          by and should be construed in accordance with US law. You and we both
          agree that the courts of US will have exclusive jurisdiction.
          <br />38.In the event that any provision of these Terms is held to be
          invalid or unenforceable, the remainder of these Terms will remain
          valid and enforceable.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
